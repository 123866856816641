/* You can add global styles to this file, and also import other style files */
@import "~@simpology/client-components/simp-styles.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto+Mono:wght@400&family=Roboto:wght@500&family=Open+Sans:wght@400&display=swap");

:root {
  // App settings
  --app-max-width: 1440px;
  --simpology-red: #e60f53;

  --brand-link: #8c1a84;
  --brand-link-hover: #5b0057;
}

body {
  @extend .simp-body;
  margin: 0px;
  background-color: #000;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

input,
textarea,
select {
  font-size: 100% !important;
}

.simp-link {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  text-decoration: underline;
}

.carousel {
  outline: none;
}

.carousel-indicators li {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50%;
  top: 60px;
  position: relative;
  background-color: #c4c4c4;
  margin-left: 6px;
  margin-right: 6px;
}

.carousel-indicators .active {
  background-color: #e60f53;
}

.carousel-control-next,
.carousel-control-prev {
  width: 24px;
  background-color: var(--simp-grey70);
}

.carousel-control-prev {
  margin-left: -24px;
}

.carousel-control-next {
  margin-right: -24px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 12px;
  height: 12px;
}

@media (min-width: 769px) {
  input,
  textarea,
  select {
    font-size: 14px !important;
  }

  .simp-link {
    font-size: 16px;
    line-height: 20px;
  }

  .carousel-indicators li {
    margin-left: 8px;
    margin-right: 8px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 56px;
    background-color: var(--simp-grey70);
  }

  .carousel-control-prev {
    margin-left: -56px;
  }

  .carousel-control-next {
    margin-right: -56px;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 1025px) {
  .simp-link {
    font-size: 18px;
    line-height: 24px;
  }
}
